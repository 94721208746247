import { getValue } from "firebase/remote-config";
import { useEffect, useState } from "react";
import { useFirebase } from "./useFirebase";

export const useRemoteConfig = (remoteConfigKey: string) => {
  const [remoteConfigValue, setRemoteConfigValue] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { remoteConfig } = useFirebase();

  useEffect(() => {
    if (!remoteConfig) return;

    const fetchRemoteConfigValue = () => {
      const parsedData = JSON.parse(getValue(remoteConfig, remoteConfigKey).asString());
      setRemoteConfigValue(parsedData);
      setIsLoading(false);
    };

    fetchRemoteConfigValue();
  }, [remoteConfig]);

  return { isLoading, remoteConfigValue };
};
