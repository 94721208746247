import { fetchAndActivate, getRemoteConfig, getValue, isSupported, RemoteConfig } from "firebase/remote-config";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import env from "src/config/env";

export const useFirebase = () => {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfig | null>(null);
  const [firestoreDB, setFirestoreDB] = useState<Firestore | null>(null);

  useEffect(() => {
    const initFirebase = async () => {
      const firebaseApp = initializeApp(env.firebase);
      setFirebaseApp(firebaseApp);
    };
    initFirebase();
  }, []);

  useEffect(() => {
    if (firebaseApp === null) return;
    const initRemoteConfig = async () => {
      const remoteConfig = getRemoteConfig(firebaseApp);
      await fetchAndActivate(remoteConfig);
      setRemoteConfig(remoteConfig);
    };

    const initFirestoreDB = async () => {
      const firestoreDB = getFirestore(firebaseApp);
      setFirestoreDB(firestoreDB);
    };

    initRemoteConfig();
    initFirestoreDB();
  }, [firebaseApp]);

  return { remoteConfig, firestoreDB };
};
