import { sendHackleData } from "./../hackle";
import { initAmplitude, sendAmplitudeData, setAmplitudeUserId, setAmplitudeUserProperties, setAmplitudeUserPropertyOnce } from "../amplitude";
import version from "version.json";

interface trackEventProps {
  eventType: any;
  eventProperties?: any;
  callback?: any;
}

export const initEventTracking = async () => {
  await initAmplitude();
};

export const trackEvent = ({ eventType, eventProperties, callback }: trackEventProps): void => {
  sendAmplitudeData(
    eventType,
    {
      ...eventProperties,
      version: version["version"],
    },
    callback
  );
  sendHackleData(eventType, eventProperties);
  // @ts-ignore
  if (window.hj) window.hj("event", eventType);
};

export const setTrackingUserId = (userId: any) => {
  setAmplitudeUserId(userId);
  // @ts-ignore
  if (window.hj) window.hj("identify", userId);
};

export const setTrackingUserProperties = (properties: any) => {
  setAmplitudeUserProperties(properties);
  // @ts-ignore
  if (window.hj) window.hj("identify", null, properties);
};

export const setTrackingUserPropertyOnce = (key: any, value: any) => {
  setAmplitudeUserPropertyOnce(key, value);
};

export function getSharedNoteMode() {
  const location = window.location.pathname;
  if (location.includes("/vdocs")) return "shared video note";
  else if (location.includes("/docs")) return "shared view note";
}
