import Head from "next/head";
import AppScript from "src/components/AppScripts";
import { Modal, Toast } from "@slid/slid-ips";
import GlobalStyle from "@/styles/globalstyles";
import { useInitialSetup } from "src/hooks";

interface LayoutProps {
  children: JSX.Element;
}

const Layout = ({ children }: LayoutProps) => {
  useInitialSetup();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />
      </Head>
      <AppScript />
      <GlobalStyle />
      <Toast className="slid-font" />
      <Modal className="slid-font" />
      <div className={`App slid-font`}>{children}</div>
    </>
  );
};

export default Layout;
