import React from "react";
import { useRouter } from "next/router";
import type { AppProps } from "next/app";

import "@/styles/bootstrap.min.css";
import "@/styles/fonts.css";
import "@/styles/App.css";
import "@/styles/editorBlocks.css";
import "@/styles/editor-js-custom.css";
import "@/styles/original-editor-styles/main.css";
import "@/styles/ImageFullScreen.css";
import "@slid/slid-ips/styles/colors.css";
import "@slid/slid-ips/styles/effects.css";
import "@slid/slid-ips/styles/globals.css";

import { appWithTranslation } from "next-i18next";

import { wrapper } from "@/redux/store";
import Providers from "src/layouts/Providers";

import Layout from "src/layouts/Layout";

function MyApp({ Component, ...rest }: AppProps) {
  const router = useRouter();

  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;

  return (
    <Providers store={store}>
      <Layout>
        <Component {...pageProps} key={router.asPath} />
      </Layout>
    </Providers>
  );
}

export default appWithTranslation(MyApp);
