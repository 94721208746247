import { store } from "../../redux/store";
import env from "@/config/env";

const SLID_EXTENSION_ID = env.end_point_url.extension_id;

export const connectPortToExtension = () => {
  if (typeof window === "undefined") return;
  if (env.currentEnv === "production") {
    return window.chrome?.runtime?.connect?.(SLID_EXTENSION_ID, { name: "SLID_WEBSITE_TO_BACKGROUND_PORT" });
  } else {
    const extensionId = store.getState()?.slidGlobal?.extensionId;
    if (extensionId) return window.chrome?.runtime?.connect?.(extensionId, { name: "SLID_WEBSITE_TO_BACKGROUND_PORT" });
  }
};
