import { useRouter } from "next/router";
import { useEffect } from "react";
import { deviceType, isIPad13 } from "react-device-detect";
import env from "src/config/env";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { setApplicationType, setDeviceType, setExtensionId, setIsExtensionInstalled, setLang, setScreenOrientation } from "@/redux/modules/slidGlobalSlice";
import { toggleSharedNoteLoadingScreen } from "src/redux/modules/sharedNotesSlice";
import { SlidMessage } from "src/types/common.types";
import { initAmplitude } from "src/utils/amplitude";
import { initEventTracking, setTrackingUserId, setTrackingUserProperties, setTrackingUserPropertyOnce } from "src/utils/eventTracking";
import { connectPortToExtension } from "src/utils/extensionInterface/connectPortToExtension";
import { sendMessageFromWebToExtension } from "src/utils/extensionInterface/sendToExtension";
import { getApplicationType } from "src/utils/utils";
import version from "version.json";
import queryString from "query-string";

const SLID_WEB_SITE_URL = env.end_point_url.slid_web_site;

export const useInitialSetup = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { errorMessage, extensionId } = useAppSelector((state) => state.slidGlobal);

  useEffect(() => {
    // location change listener
    const handleRouteChange = (nextPath: string) => {
      // showNoteLoadingState
      const isPlainToVdocs = router.pathname.includes("share/docs") && nextPath.includes("share/vdocs");
      const isVdocsToPlainNote = router.pathname.includes("share/vdocs") && nextPath.includes("share/docs");
      if (isPlainToVdocs || isVdocsToPlainNote) dispatch(toggleSharedNoteLoadingScreen(true));

      // Refresh Error when error is happend
      if (errorMessage) {
        router.reload();
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const updateLang = (lang: string) => {
      lang = lang.toLowerCase();
      if (lang === "en-us") lang = "en";
      if (lang === "ko-kr") lang = "ko";
      if (lang !== "ko" && lang !== "en") lang = "en";
      dispatch(setLang(lang));
    };

    if (!router.isReady) return;

    const { locale, query } = router;
    let curLang: string | undefined | string[] = "en";
    curLang = locale ?? query.lang ?? "en";
    updateLang(curLang as string);
  }, [router.isReady]);

  useEffect(() => {
    if (extensionId && env.currentEnv !== "production") setAmplitudeDeviceIdFromExtension();
  }, [extensionId]);

  const setAmplitudeDeviceIdFromExtension = () => {
    setTrackingUserId(null);
    setTrackingUserProperties({});

    const externalPort = connectPortToExtension();
    if (externalPort) {
      externalPort.onMessage.addListener((message: SlidMessage) => {
        if (message) {
          const { action, data } = message;
          if (action === "BACK_TO_WEB_getAmplitudeDeviceId" && data.deviceId) {
            initAmplitude(data.deviceId);
          }
        }
      });
      externalPort.postMessage({
        action: "WEB_TO_BACK_getAmplitudeDeviceId",
      });
    }
  };

  const checkIfExtensionIsInstalled = async () => {
    // check whether extension is installed or not.
    try {
      await sendMessageFromWebToExtension({
        action: "WEBSITE_TO_BACK_checkExtensionInstallation",
        data: {},
        responseHandler: (response: SlidMessage) => {
          if (response?.action === "BACK_TO_WEBSITE_checkExtensionInstallation" && response.data.isExtensionInstalled) {
            dispatch(setIsExtensionInstalled(response.data.isExtensionInstalled));
          } else {
            dispatch(setIsExtensionInstalled(false));
          }
        },
      });
    } catch (e) {
      dispatch(setIsExtensionInstalled(false));
    }
  };

  const checkScreenOrientation = () => {
    if (typeof window === "undefined") return;
    const screenOrientation = Math.abs(window.orientation) !== 90 ? "vertical" : "horizontal";
    dispatch(setScreenOrientation(screenOrientation));
  };

  useEffect(() => {
    if (env.currentEnv === "test") {
      console.log(`%c🚨 Current Slid web version: ${version["version"]} 🚨`, "background: #4287f5; color: #ffffff");
    }

    // set application type in redux
    dispatch(setApplicationType(getApplicationType()));

    // set device type
    const curDeviceType = isIPad13 ? "tablet" : deviceType === "browser" ? "desktop" : deviceType;
    dispatch(setDeviceType(curDeviceType));

    // check whether extension is installed or not.
    checkIfExtensionIsInstalled();

    // set extension id for dev/test env
    if (env.currentEnv !== "production") {
      // Works well on edge, whale, chrome
      window.addEventListener("message", (e) => {
        if (e.origin === `${SLID_WEB_SITE_URL}`) {
          if (e.data.action === "INJECTED_CONTENT_TO_WEB_getExtensionId") {
            dispatch(setExtensionId(e.data.data));
            dispatch(setIsExtensionInstalled(true));
          }
        }
      });
    }

    // initialie tracking
    async function initTracking() {
      await initEventTracking();
    }
    initTracking();

    // Setting `source` property if user is coming from specific marketing campaign.
    const marketingCampaignSource = queryString.parse(window.location.search).ref;
    if (marketingCampaignSource) setTrackingUserPropertyOnce("source", marketingCampaignSource);

    // screen orientation setup
    if (window.orientation !== undefined) {
      checkScreenOrientation();
      window.addEventListener("orientationchange", checkScreenOrientation);
    }

    if (env.currentEnv === "production") setAmplitudeDeviceIdFromExtension();

    // cleaning up
    return () => {
      window.removeEventListener("orientationchange", checkScreenOrientation);
    };
  }, []);
};
