import { HYDRATE } from "next-redux-wrapper";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

const name = "slidGlobal";

export interface SlidGlobalState {
  lang: string;
  userData: any;
  applicationType: string | null;
  errorMessage: string | null;
  screenOrientation: string;
  deviceType: string | null;
  notifications: any;
  isExtensionInstalled: boolean | null;
  extensionId: string | null;

  // used to be in app.js
  stableVersions: any;
}

const getInitialLanguage = () => {
  if (typeof window === "undefined") return "";

  let initialLang = window.navigator.language;
  initialLang.toLowerCase();
  if (initialLang === "ko-kr") initialLang = "ko";
  else initialLang = "en";

  return initialLang;
};

const initialState: SlidGlobalState = {
  lang: getInitialLanguage(),
  userData: null,
  applicationType: null, // web, extension, desktop
  errorMessage: null,
  screenOrientation: "horizontal",
  deviceType: null, // "PC", "MobileOr"
  notifications: null,
  isExtensionInstalled: null,
  extensionId: null,

  // used to be in app.js
  stableVersions: {},
};

const slidGlobalSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
    setUserData: (state, action: PayloadAction<any>) => {
      state.userData = action.payload;
    },
    setApplicationType: (state, action: PayloadAction<string>) => {
      state.applicationType = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setScreenOrientation: (state, action: PayloadAction<string>) => {
      state.screenOrientation = action.payload;
    },
    setDeviceType: (state, action: PayloadAction<string>) => {
      state.deviceType = action.payload;
    },
    setNotifications: (state, action: PayloadAction<any>) => {
      state.notifications = action.payload;
    },
    setIsExtensionInstalled: (state, action: PayloadAction<boolean>) => {
      state.isExtensionInstalled = action.payload;
    },
    setExtensionId: (state, action: PayloadAction<string>) => {
      state.extensionId = action.payload;
    },
    setStableVersions: (state, action: PayloadAction<any>) => {
      state.stableVersions = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.slidGlobal,
      };
    },
  },
});

export const { setApplicationType, setDeviceType, setErrorMessage, setExtensionId, setIsExtensionInstalled, setLang, setNotifications, setScreenOrientation, setStableVersions, setUserData } =
  slidGlobalSlice.actions;
export default slidGlobalSlice.reducer;
