import { HYDRATE } from "next-redux-wrapper";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

const name = "sharedNotes";

export interface ShareNotesState {
  foldVideo: boolean;
  recommendedNotes: any;
  previousNote: any[];
  isNoteLoading: boolean;
  showImageFullScreen: { show: boolean; src: string };
  videoIndex: any;
  currentDocument: any;
  videoPlayerRef: any;
}

const initialState: ShareNotesState = {
  foldVideo: false,
  recommendedNotes: null,
  previousNote: [],
  isNoteLoading: true,
  showImageFullScreen: { show: false, src: "" },
  videoIndex: 0,
  currentDocument: null,
  videoPlayerRef: null,
};

// setVideoIndex, setVideoPlayerRef;

const shareNotesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFoldVideo: (state, action: PayloadAction<boolean>) => {
      state.foldVideo = action.payload;
    },
    setRecommendedNote: (state, action: PayloadAction<any>) => {
      state.recommendedNotes = action.payload;
    },
    setPreviousNote: (state, action: PayloadAction<any>) => {
      state.previousNote = action.payload;
    },
    toggleSharedNoteLoadingScreen: (state, action: PayloadAction<boolean>) => {
      state.isNoteLoading = action.payload;
    },
    setShowImageFullScreen: (state, action: PayloadAction<{ show: boolean; src: string }>) => {
      state.showImageFullScreen = action.payload;
    },
    setDocument: (state, action: PayloadAction<any>) => {
      state.currentDocument = action.payload;
    },
    setVideoIndex: (state, action: PayloadAction<any>) => {
      state.videoIndex = action.payload;
    },
    setVideoPlayerRef: (state, action: PayloadAction<any>) => {
      state.videoPlayerRef = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.sharedNotes,
      };
    },
  },
});

export const { setFoldVideo, setPreviousNote, setRecommendedNote, toggleSharedNoteLoadingScreen, setShowImageFullScreen, setDocument, setVideoIndex, setVideoPlayerRef } = shareNotesSlice.actions;
export default shareNotesSlice.reducer;
