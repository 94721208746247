import { createInstance, HackleProvider } from "@hackler/react-sdk";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import env from "@/config/env";
import { AnyAction, ThunkMiddleware } from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";

interface ProviderProps {
  store: ToolkitStore<any, AnyAction, [ThunkMiddleware<any, AnyAction, undefined>]>;
  children: JSX.Element;
}

const Providers = ({ children, store }: ProviderProps) => {
  const HACKLE_CLIENT = createInstance(env.api_key.hackle_key, { exposureEventDedupIntervalMillis: 1000 });

  return (
    <Provider store={store}>
      <CookiesProvider>
        <HackleProvider hackleClient={HACKLE_CLIENT} supportSSR>
          {children}
        </HackleProvider>
      </CookiesProvider>
    </Provider>
  );
};

export default Providers;
