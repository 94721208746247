import env from "@/config/env";
import version from "version.json";
import { getApplicationType } from "./utils";
import * as Hackle from "@hackler/javascript-sdk";

const hackleClient = Hackle.createInstance(env.api_key.hackle_key);

export const sendHackleData = (eventType: any, eventProperties: any) => {
  const applicationType = getApplicationType();

  const eventKey = eventType.replaceAll(" ", "_");

  hackleClient.onReady(() => {
    //@ts-ignore
    hackleClient.track({ key: eventKey, properties: { ...eventProperties, version: version["version"], platform: applicationType, domain: "slid web app" } });
  });
};
