import version from "version";
import env from "@/config/env";

const SLID_EXTENSION_ID = env.end_point_url.extension_id;

export const sendChromeExtensionVersionRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_checkExtensionVersion",
    data: {},
  });
};

export const sendWebVersionToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_sendWebVersion",
    data: {
      webVersion: version["version"],
    },
  });
};

export const sendVideoInfoRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_getVideoInfo",
    data: {},
  });
};

export const LEGACY_sendVideoInfoRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_checkForListener",
    data: {},
  });
};

export const sendVideoPlaceholderPositionToParentWindow = ({ videoPlaceholderPosition }) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_sendVideoRect",
    data: {
      videoRect: {
        top: videoPlaceholderPosition.top,
        left: videoPlaceholderPosition.left,
        width: videoPlaceholderPosition.width,
        height: videoPlaceholderPosition.height,
      },
    },
  });
};

export const sendScreenCaptureRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_captureCurrentArea",
    data: {},
  });
};

export const sendOneClickCaptureRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_oneClickCaptureCurrentArea",
    data: {},
  });
};

export const sendSetCaptureAreaRequestToParentWindow = (isCaptureAreaActive) => {
  sendMessageToParentContent({
    action: isCaptureAreaActive ? "IFRAME_TO_CONTENT_setCaptureArea" : "IFRAME_TO_CONTENT_removeCaptureArea",
    data: {},
  });
};

export const sendSetSnipCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_setCaptureAreaForSnipCapture",
    data: {},
  });
};

export const sendRemoveSnipCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_removeCaptureAreaForSnipCapture",
    data: {},
  });
};

export const sendSetFocusOnIframeRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_setFocusOnIframe",
    data: {},
  });
};

export const sendCancleCaptureAreaRequestToParentWindow = (isCaptureAreaActive) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_cancleCaptureArea",
    data: {},
  });
};

export const sendSetOneClickCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_setCaptureAreaForOneClickCapture",
    data: {},
  });
};

export const sendApplyCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_setCurrentCaptureArea",
    data: {},
  });
};

export const LEGACY_sendResetCaptureAreaRequestToParentWindow = ({ videoPlaceholderPosition }) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_sendVideoRect",
    data: {
      videoRect: {
        top: videoPlaceholderPosition.top,
        left: videoPlaceholderPosition.left,
        width: videoPlaceholderPosition.width,
        height: videoPlaceholderPosition.height,
      },
    },
  });
};

export const sendResetCaptureAreaRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_resetCaptureArea",
    data: {},
  });
};

export const sendResetCaptureAreaForOneClickCaptureRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_resetCaptureAreaForOneClickCapture",
    data: {},
  });
};

export const sendVideoDownloadUrlRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_getVideoDownloadUrl",
    data: {},
  });
};

export const sendVideoControlRequestToParentWindow = ({ type, value }) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_controlVideo",
    data: {
      type: type,
      value: value,
    },
  });
};

export const sendVideoSeekToTimestampRequestToParentWindow = ({ clipTs }) => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_seekToTimestamp",
    data: {
      timestamp: clipTs,
    },
  });
};

export const sendStartRecordClipRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_StartRecordClip",
    data: {
      audioBitsPerSecond: 64000,
      videoWidth: 1280,
      videoHeight: 720,
      videoFrameRate: 24,
    },
  });
};

export const sendStopRecordClipRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_StopRecordClip",
    data: {},
  });
};

export const sendSplitOnDragStartRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_SplitOnDragStart",
    data: {},
  });
};

export const sendSplitOnDragEndRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_SplitOnDragEnd",
    data: {},
  });
};

export const sendHideVideoRequestToParentWindow = () => {
  sendMessageToParentContent({
    action: "IFRAME_TO_CONTENT_sendVideoRect",
    data: {
      videoRect: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
    },
  });
};

const sendMessageToParentContent = ({ action, data }) => {
  window.parent.postMessage(
    {
      action,
      data,
    },
    "*"
  );
};

export const sendMessageFromWebToExtension = async ({ action, data, responseHandler }) => {
  await window.chrome.runtime.sendMessage(SLID_EXTENSION_ID, { action, data }, responseHandler);
};
