import * as Sentry from "@sentry/nextjs";
import env from "@/config/env";

const SLID_EDGE_EXTENSION_DOWNLOAD_URL = env.end_point_url.edge_extension;
const SLID_CHROME_EXTENSION_DOWNLOAD_URL = env.end_point_url.slid_extension;
const SLID_WEB_SITE_URL = env.end_point_url.slid_web_site;

export const getRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const toUnicode = (theString) => {
  let unicodeString = "";
  for (let i = 0; i < theString.length; i++) {
    let theUnicode = theString.charCodeAt(i).toString(16).toUpperCase();
    while (theUnicode.length < 4) {
      theUnicode = "0" + theUnicode;
    }
    theUnicode = "\\u" + theUnicode;
    unicodeString += theUnicode;
  }
  return unicodeString;
};

export const setClipboard = (value) => {
  var tempInput = document.createElement("textarea");
  tempInput.style = "height: 0; overflow:hidden;";
  tempInput.innerText = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
};

export const getPrettyDate = (dateString) => {
  let dateStringUTC = dateString + " UTC";
  if (Number.isNaN(new Date(dateStringUTC).getFullYear())) dateStringUTC = dateString.replace(" ", "T");
  let y = new Date(dateStringUTC).getFullYear();
  let m = new Date(dateStringUTC).getMonth() + 1;
  if (m < 10) m = `0${m}`;
  let d = new Date(dateStringUTC).getDate();
  if (d < 10) d = `0${d}`;
  let hh = new Date(dateStringUTC).getHours();
  if (hh < 10) hh = `0${hh}`;
  let mm = new Date(dateStringUTC).getMinutes();
  if (mm < 10) mm = `0${mm}`;

  return `${y}-${m}-${d} ${hh}:${mm}`;
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isElectron = () => {
  /* check is we are launching from the desktop app */

  // Renderer process
  if (typeof window !== "undefined" && typeof window.process === "object" && window.process.type === "renderer") {
    return true;
  }
  // Main process
  if (typeof process !== "undefined" && typeof process.versions === "object" && !!process.versions.electron) {
    return true;
  }
  // Detect the user agent when the `nodeIntegration` option is set to true
  if (typeof navigator === "object" && typeof navigator.userAgent === "string" && navigator.userAgent.indexOf("Electron") >= 0) {
    return true;
  }
  return false;
};

export const isIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const findLatestOfTwoVersions = (version1, version2) => {
  // returns the more recent of the two versions
  // assumes the versions are in format: xx.yy.zz

  const version1Segments = version1.split(".");
  const version2Segments = version2.split(".");

  for (let i = 0; i < 3; i++) {
    const intInVersion1 = parseInt(version1Segments[i]);
    const intInVersion2 = parseInt(version2Segments[i]);
    if (intInVersion1 > intInVersion2) return version1;
    if (intInVersion2 > intInVersion1) return version2;
  }

  // versions are the same
  return version1;
};

// check receivedData from new version or stable version (extension).
export const convertReceivedDataIntoObjectType = (messageData) => {
  try {
    if (typeof messageData === "string") {
      return JSON.parse(messageData);
    } else {
      // should be Object type
      return messageData;
    }
  } catch (error) {
    // case: messageData is string but not object string.
  }
};

export const cloneDeep = (obj) => {
  let copy = {};
  if (Array.isArray(obj)) {
    copy = obj.slice().map((v) => {
      return cloneDeep(v);
    });
  } else if (typeof obj === "object" && obj !== null) {
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = cloneDeep(obj[attr]);
      }
    }
  } else {
    copy = obj;
  }
  return copy;
};

export const strictParseInt = (value) => {
  if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) return Number(value);
  return NaN;
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const romanize = (num) => {
  if (isNaN(num)) return NaN;
  const digits = String(+num).split(""),
    key = ["", "c", "cc", "ccc", "cd", "d", "dc", "dcc", "dccc", "cm", "", "x", "xx", "xxx", "xl", "l", "lx", "lxx", "lxxx", "xc", "", "i", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix"];
  let roman = "",
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
};

export const alphabetize = (num) => {
  let ordA = "a".charCodeAt(0);
  let ordZ = "z".charCodeAt(0);
  let len = ordZ - ordA;

  let s = "";
  while (num >= 0) {
    s = String.fromCharCode((num % len) + ordA - 1) + s;
    num = Math.round(num / len) - 1;
  }
  return s;
};

export const makeElement = (tagName, classNames = null, attributes = {}) => {
  const el = document.createElement(tagName);
  el.spellcheck = false;

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};

export function getCaretPos(element) {
  let caretOffset = 0;
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  let sel;
  if (typeof win.getSelection != "undefined") {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type != "Control") {
    const textRange = sel.createRange();
    const preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint("EndToEnd", textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

export const sendMessageToPrimary = ({ message, port }) => {
  /* 
    Sends messages to the primary window of Slid Desktop
    */
  if (port) {
    port.postMessage(message);
    return true;
  } else {
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureMessage("SLID_DESKTOP_IFRAME_DID_NOT_RECEIVE_PORT");
    });
    return false;
  }
};

export const setUserTypeSticker = ({ type, ColorTypes }) => {
  if (type === "starter") {
    return ColorTypes.Orange;
  }
  if (type === "basic") {
    return ColorTypes.Green;
  }
  if (type === "pro") {
    return ColorTypes.Purple;
  }
};
export const getApplicationType = () => {
  let applicationType;
  if (isElectron()) {
    applicationType = "desktop";
  } else if (isIframe()) {
    applicationType = "extension";
  } else {
    applicationType = "web";
  }

  return applicationType;
};

export const getBrowserType = () => {
  if (typeof window == "undefined") return "Chrome";
  const agent = window.navigator.userAgent.toLowerCase();
  if (agent.indexOf("edg") !== -1) {
    return "Edge";
  } else if (agent.indexOf("whale") !== -1) {
    return "Whale";
  } else if (agent.indexOf("chrome") !== -1) {
    return "Chrome";
  } else {
    return "Chrome";
  }
};

export const openExtensionWebStoreByBrowser = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  // Since whale store is not working properly with installing extension, open chrome store also on whale browser
  // if (agent.indexOf("whale") !== -1) {
  //   window.location = env[env["env"]]["end_point_url"]["whale_extension"];
  // }
  if (agent.indexOf("edg") !== -1) {
    window.location = SLID_EDGE_EXTENSION_DOWNLOAD_URL;
  } else {
    window.location = SLID_CHROME_EXTENSION_DOWNLOAD_URL;
  }
};

export const openExtensionWebStoreInNewTab = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  // Since whale store is not working properly with installing extension, open chrome store also on whale browser
  // if (agent.indexOf("whale") !== -1) {
  //   window.open(env[env["env"]]["end_point_url"]["whale_extension"]);
  // }
  if (agent.indexOf("edg") !== -1) {
    window.open(SLID_EDGE_EXTENSION_DOWNLOAD_URL);
  } else {
    window.open(SLID_CHROME_EXTENSION_DOWNLOAD_URL);
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getUserLocationInfo = () => {
  return fetch("https://get.geojs.io/v1/ip/country.json").then((res) => res.json());
};

export const copyToClipboard = (contentToCopy) => {
  const tempTextareaForCopy = document.createElement("textarea");
  tempTextareaForCopy.position = "absolute";
  tempTextareaForCopy.top = `-9999999px`;
  tempTextareaForCopy.left = `-9999999px`;
  document.body.appendChild(tempTextareaForCopy);
  tempTextareaForCopy.value = contentToCopy;
  // TODO: When we use this method inside Dropdown.item, tempTextareaForCopy.select() is not working without setTimeout.
  // We don't know the exact reason. Please Refactor someday.
  setTimeout(() => {
    tempTextareaForCopy.select();
    document.execCommand("copy");
    tempTextareaForCopy.remove();
  });
};

export const checkIsNewUser = (userData) => {
  const currentTime = Date.now();
  const createdTime = new Date(userData.created_time);
  // Set as new user when gap between current time and account created time is less than 1 minute
  return currentTime - createdTime.getTime() < 60000;
};

export const getShareLink = ({ noteType, noteTitle, noteKey }) => {
  const titleToUrlSanitizer = (title) => {
    const url = title.toLowerCase().split(" "); // 1, 2
    const urlWithoutSpecialCharacters = url.map((word) => {
      return word.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, "");
    }); // 3

    const sanitizedUrl = urlWithoutSpecialCharacters.filter((word) => word.length > 0); // 4
    return sanitizedUrl.join("-").substring(0, 100); // 5
  };

  const prefix = `/share/${noteType}`;
  const title = noteTitle ? noteTitle : "";
  const sanitized_title = titleToUrlSanitizer(title);
  const documentKey = noteKey ? noteKey : "";
  return prefix + `/${sanitized_title ? sanitized_title + "-" : ""}` + documentKey;
};

export function isIOS() {
  let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || navigator.platform === "MacIntel";
  return isIOS;
}
export const isEmbededSharedNote = (documentKey) => {
  return !window.location.pathname.includes(documentKey);
};
