import { Global, css } from "@emotion/react";
import * as styled from "@emotion/styled";

const style = css`
  /* ul style is not set as global css due to keep showing list-style(bullet, number prefix) on editor list blocks */

  :root {
    font-family: inherit;
  }

  html {
    /* 62.5% equals to 10px: 1rem = 10px */
    font-size: 62.5%;

    @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 799px) {
      /* 75% equals to 12px: 1rem = 12px */
      font-size: 75%;
    }
  }

  html,
  body {
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    font-family: "SlidTextStyle", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  }

  body {
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    font-size: 100%;
  }

  button {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    outline-style: none;
    border: none;
    box-shadow: none;
    background: inherit;
    outline-style: none;
    padding: 0;

    &:focus {
      outline: 0;
    }
  }

  a {
    color: var(--blue8);
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: var(--blue9);
    }
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  code {
    font-family: source-code-pro;
  }

  /* adjust bootstrap css */
  .form-control,
  .custom-select,
  .dropdown-menu {
    font-size: inherit;
  }

  .toast-body {
    font-size: 1.4rem !important;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .spinner-border {
    width: 32px;
    height: 32px;
  }

  .spinner-border-sm {
    width: 11px;
    height: 11px;
  }

  .tooltip-inner span {
    background-color: var(--gray17) !important;
    font-size: 1.2rem !important;
    padding: 4px 6px !important;
  }
`;

const GlobalStyle = () => {
  return <Global styles={style} />;
};

export default GlobalStyle;
