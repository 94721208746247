// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import version from "version.json";
import env from "@/config/env";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || env.end_point_url.sentry;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  release: "slid-web-site@" + version["version"],
  environment: env.currentEnv,
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  beforeSend(event) {
    // Editor JS related error - Ignore until we refactor how we use Editor JS
    if (event.exception && event.exception.values[0].value.includes("Cannot read property 'holder' of undefined")) return null;
    if (event.exception && event.exception.values[0].value.includes("Cannot read property 'parentNode' of null")) return null;
    if (event.exception && event.exception.values[0].value.includes("Cannot set property 'selected' of undefined")) return null;
    if (event.exception && event.exception.values[0].value.includes("Cannot read property 'currentInput' of undefined")) return null;
    if (event.exception && event.exception.values[0].value.includes("element with ID «editor-js")) return null;
    if (event.exception && event.exception.values[0].value.includes("Pass correct holder's ID.")) return null;
    if (event.exception && event.exception.values[0].value.includes("Editor's content can not be saved in read-only mode")) return null;
    if (event.exception && event.exception.values[0].value.includes("Cannot read properties of null (reading 'parentNode')")) return null;
    if (event.exception && event.exception.values[0].value.includes("Cannot read properties of undefined (reading 'normalize')")) return null;
    if (event.exception && event.exception.values[0].value.includes(".some is not a function")) return null;

    // Firebase Remote config error - Ignore until we refactor how we use firebase
    if (event.exception && event.exception.values[0].value.includes("Remote Config: Error thrown when opening storage.")) return null;
    if (event.exception && event.exception.values[0].value.includes("Remote Config: The config fetch request timed out. ")) return null;

    // Color picker error - Ignor until we refactor image canvas
    if (event.exception && event.exception.values[0].value.includes("Cannot read properties of undefined (reading 'colorCollections')")) return null;

    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
