import amplitude from "amplitude-js";
import env from "@/config/env";

export const initAmplitude = async (deviceId = null) => {
  const AMPLITUDE_API_KEY = env.api_key.amplitude;
  amplitude.getInstance().init(AMPLITUDE_API_KEY, null, { deviceId });
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const setAmplitudeUserPropertyOnce = (key, value) => {
  const identify = new amplitude.Identify().setOnce(key, value);
  amplitude.getInstance().identify(identify);
};

export const sendAmplitudeData = (eventType, eventProperties, callback) => {
  amplitude.getInstance().logEvent(eventType, { ...eventProperties, domain: eventProperties.domain ? eventProperties.domain : "slid web site" }, callback);
};
