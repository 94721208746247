import { createWrapper } from "next-redux-wrapper";
import { configureStore, Reducer, AnyAction } from "@reduxjs/toolkit";
import env from "@/config/env";

import slidGlobal from "./modules/slidGlobalSlice";
import sharedNotes from "./modules/sharedNotesSlice";
import { IPSReducer } from "@slid/slid-ips";

interface IPSReducerType {
  toast: Reducer<any, AnyAction>;
  modal: Reducer<any, AnyAction>;
  alert: Reducer<any, AnyAction>;
}

const typedIPSReducer: IPSReducerType = IPSReducer;

export const store = configureStore({
  reducer: {
    slidGlobal,
    sharedNotes,
    ...typedIPSReducer,
  },
  devTools: env.currentEnv !== "production" ? true : false,
});

const makeStore = () => store;

export const wrapper = createWrapper(makeStore);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
